import { Box, Text } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const DailyBMRateCopy = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { summary } = useAppLead();

  return (
    <Text {...props} ref={ref}>
      {summary.bmr.perDay}{' '}
      <Box as='span' fontSize='60%' color='rgba(0, 0, 0, 0.6)'>
        calories/day
      </Box>
    </Text>
  );
});

DailyBMRateCopy.displayName = 'DailyBMRateCopy';
