export const constants: { [key: string]: { [key: string]: string } } = {
  en: {
    full_name: 'Full name',
    submit_secure_payment: 'Submit secure payment',
    or_pay_with_card: 'or pay with a card',
    credit_card: 'Credit Card',
    name_on_card: 'Name on card',
    card_number: 'Card number',
    expiration: 'Expiration',
  },
  es: {
    full_name: 'Nombre y apellidos',
    submit_secure_payment: 'Enviar pago seguro',
    or_pay_with_card: 'o pagar con tarjeta',
    credit_card: 'Tarjeta de crédito',
    name_on_card: 'Nombre en la tarjeta',
    card_number: 'Número de tarjeta',
    expiration: 'Caducidad',
  },
  de: {
    full_name: 'Vollständiger Name',
    submit_secure_payment: 'Sichere Zahlung einreichen',
    or_pay_with_card: 'oder mit einer Karte bezahlen',
    credit_card: 'Kreditkarte',
    name_on_card: 'Name auf der Karte',
    card_number: 'Kartennummer',
    expiration: 'Verfallsdatum',
  },
  fr: {
    full_name: 'Nom complet',
    submit_secure_payment: 'Soumettre un paiement sécurisé',
    or_pay_with_card: 'ou payer par carte',
    credit_card: 'Carte de crédit',
    name_on_card: 'Nom sur la carte',
    card_number: 'Numéro de la carte',
    expiration: 'Expiration',
  },
  it: {
    full_name: 'Nome e cognome',
    submit_secure_payment: 'Invia un pagamento sicuro',
    or_pay_with_card: 'o pagare con una carta',
    credit_card: 'Carta di credito',
    name_on_card: 'Nome sulla scheda',
    card_number: 'Numero di carta',
    expiration: 'Scadenza',
  },
  pt: {
    full_name: 'Nome completo',
    submit_secure_payment: 'Enviar pagamento seguro',
    or_pay_with_card: 'ou pagar com cartão',
    credit_card: 'Cartão de crédito',
    name_on_card: 'Nome no cartão',
    card_number: 'Número do cartão',
    expiration: 'Prazo de validade',
  },
};

export const ERRORS = {
  generic: `Oopsie! Something went wrong with your payment. Please double-check your information and try again. If the issue persists, contact our support team. Thank you!`,
  missingPaymentMethod: `Oopsie! We couldn't process your payment with that card. Try another card or payment method. Thank you!`,
  loadingIssue: `Oopsie! Looks like we're facing issues with our current payment provider. Feel free to try another one for a smoother experience or refresh the page. If you need assistance or have any questions, reach out to our support team. Thanks for your understanding!`,
};

export const METHODS = {
  paypal: 'paypal',
};
