import { UserContext } from '@shared/common';
import summarize from '@shared/summarize';
import { useContext } from 'react';

import { Lead } from '~/types';

export const useAppLead = () => {
  const context = useContext(UserContext);
  const user = context.user as Lead;
  const summary = summarize(
    {
      age: Number(user.quizAnswers.age),
      height: parseFloat(user.quizAnswers.height),
      weight: parseFloat(user.quizAnswers.weight),
      targetWeight: parseFloat(user.quizAnswers.targetWeight),
    },
    { isMetric: user.isMetric },
  );

  return {
    ...user,
    updateLead: (data: Partial<Lead>) =>
      context.setUser({
        ...user,
        ...data,
      }),
    updateQuizAnswers: (data: Partial<Lead['quizAnswers']>) =>
      context.setUser({ ...user, quizAnswers: { ...user.quizAnswers, ...data } }),
    summary,
  };
};
