import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import { FC, ForwardedRef, forwardRef } from 'react';

import { config } from '~/config';
import { useCheckout } from '~/hooks/useCheckout';

export const OrderSummary: FC<FlexProps> = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { selectedPlan, price, finalPrice, currency } = useCheckout();

  return (
    <Flex gap='0.5rem' flexDir='column' {...props} ref={ref}>
      <Flex justifyContent='space-between' color='black90'>
        <Text>
          {config.NAME_SHORT} {selectedPlan?.name}
        </Text>
        <Text>{generalUtils.formatPrice(price, currency)}</Text>
      </Flex>
      <Flex justifyContent='space-between' color='error'>
        <Text fontSize={{ base: '14px', md: '16px' }}>
          Discount (-{(100 - (finalPrice / price) * 100).toFixed(0)}%)
        </Text>
        <Text>-{generalUtils.formatPrice(price - finalPrice, currency)}</Text>
      </Flex>
      <Flex
        justifyContent='space-between'
        paddingTop='0.75rem'
        borderTop='1px solid rgba(0, 0, 0, 0.1)'
        marginTop='0.75rem'
      >
        <Text fontFamily='heading' fontSize='24px' color='black'>
          Total:
        </Text>
        <Text fontFamily='heading' fontSize='24px' color='black'>
          {generalUtils.formatPrice(finalPrice, currency)}
        </Text>
      </Flex>
    </Flex>
  );
});

OrderSummary.displayName = 'OrderSummary';
