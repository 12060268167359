import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';
import { Animate } from '@shared/animations';
import { Button } from '@shared/common';

import {
  CheckoutForm,
  DailyBMRateCopy,
  EmailForm,
  GenderSelection,
  ModalButtonWithQuestions,
  PlanSelection,
  SummaryGrid,
  SummaryWeightChart,
  TimeBanner,
} from '~/components';
import { config } from '~/config';

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: config.PLASMIC.PROJECT_ID,
      token: config.PLASMIC.PROJECT_TOKEN,
    },
  ],
  preview: config.IS_DEV,
});

PLASMIC.registerComponent(Button, {
  name: 'Button',
  props: {
    children: {
      type: 'slot',
    },
    variant: {
      type: 'choice',
      options: ['primary', 'secondary', 'black'],
      defaultValue: 'primary',
    },
    action: {
      type: 'choice',
      options: ['navigate', 'scroll'],
      defaultValue: 'navigate',
    },
    value: {
      type: 'string',
    },
  },
});

PLASMIC.registerComponent(EmailForm, {
  name: 'EmailForm',
  props: {},
});

PLASMIC.registerComponent(PlanSelection, {
  name: 'PlanSelection',
  props: {},
});

PLASMIC.registerComponent(CheckoutForm, {
  name: 'CheckoutForm',
  props: {},
});

PLASMIC.registerComponent(GenderSelection, {
  name: 'GenderSelection',
  props: {},
});

PLASMIC.registerComponent(SummaryWeightChart, {
  name: 'SummaryWeightChart',
  props: {},
});

PLASMIC.registerComponent(SummaryGrid, {
  name: 'SummaryGrid',
  props: {},
});

PLASMIC.registerComponent(TimeBanner, {
  name: 'TimeBanner',
  props: {},
});

PLASMIC.registerComponent(Animate, {
  name: 'Animate',
  props: {
    children: {
      type: 'slot',
    },
  },
});

PLASMIC.registerComponent(ModalButtonWithQuestions, {
  name: 'ModalButtonWithQuestions',
  props: {},
});

PLASMIC.registerComponent(DailyBMRateCopy, {
  name: 'DailyBMRateCopy',
  props: {},
});
