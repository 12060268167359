import { useQuery } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';
import { AppProduct } from '~/types';

export const useProductsQuery = () => {
  return useQuery<{ products: AppProduct[] }>(FunctionName.FETCH_PRODUCTS, {
    queryFn: () => APIClient.get(getAPIUrl(FunctionName.FETCH_PRODUCTS)),
    staleTime: Infinity,
  });
};
