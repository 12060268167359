import { Button, Flex } from '@chakra-ui/react';
import Link from 'next/link';

import { useAppLead } from '~/hooks/useAppLead';
import { useTracking } from '~/hooks/useTracking';
import { AppRoute } from '~/types';

export const GenderSelection = () => {
  const { updateQuizAnswers } = useAppLead();
  const { quizQuestionAnswer } = useTracking();

  const handleSelection = (gender: 'male' | 'female') => {
    updateQuizAnswers({ gender });

    quizQuestionAnswer({
      category: '01_gender',
      label: gender,
    });
  };

  return (
    <Flex width='100%' gap='0.5rem' maxW='32rem'>
      <Link href={AppRoute.QUIZ}>
        <Button variant='green' onClick={() => handleSelection('male')} textTransform='uppercase'>
          Weight loss
          <br />
          for men
        </Button>
      </Link>
      <Link href={AppRoute.QUIZ}>
        <Button onClick={() => handleSelection('female')} textTransform='uppercase'>
          Weight loss
          <br />
          for women
        </Button>
      </Link>
    </Flex>
  );
};
