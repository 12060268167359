import cookie from 'js-cookie';

export const useCookies = () => {
  return {
    fbclid: cookie.get('_fbc') || null,
    fbpixel: cookie.get('_fbp') || null,
    ip: cookie.get('x-ip') || null,
    city: cookie.get('x-city') || null,
    countryCode: cookie.get('x-country-code') || null,
    country: cookie.get('x-country') || null,
    region: cookie.get('x-region') || null,
    userAgent: typeof navigator === 'undefined' ? null : navigator.userAgent,
  };
};
