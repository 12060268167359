import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import CircleActivitySVG from '~/assets/icons/circle_activity.svg';
import CircleAgeSVG from '~/assets/icons/circle_age.svg';
import CircleCurrentWeightSVG from '~/assets/icons/circle_current_weight.svg';
import CircleExerciseSVG from '~/assets/icons/circle_exerice.svg';
import CircleHeightSVG from '~/assets/icons/circle_height.svg';
import CircleLifestyleSVG from '~/assets/icons/circle_lifestyle.svg';
import CircleTargetWeightSVG from '~/assets/icons/circle_target_weight.svg';
import { useAppLead } from '~/hooks/useAppLead';

import { BMIChart } from '../BMIChart';

export const SummaryGrid = () => {
  const { quizAnswers, summary, isMetric } = useAppLead();
  const workoutTimeDedication = quizAnswers.cardio_time_dedication || '';
  const lifestyle = quizAnswers.how_often_do_you_exercise || '';

  return (
    <Box width='100%'>
      <Grid
        gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
        gap={{ base: '0.5rem', md: '0.75rem' }}
        textAlign={{ base: 'inherit', md: 'center' }}
        marginBottom={{ base: '0.5rem', md: '0.75rem' }}
      >
        <Flex
          gap={{ base: '0.75rem', md: '0.5rem' }}
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding={{ base: '0.75rem 0.5rem', md: '1.5rem' }}
          borderRadius='0.5rem'
          backgroundColor='#fff'
          border='1px solid rgba(0, 0, 0, 0.1)'
        >
          <Flex justifyContent='center'>
            <CircleAgeSVG />
          </Flex>
          <Box flex={1}>
            <Text fontFamily='heading' fontSize={{ base: '28px', md: '36px' }} color='black' lineHeight='100%'>
              {summary.age}
            </Text>
            <Text fontSize={{ base: '12px', md: '14px' }}>years old</Text>
          </Box>
        </Flex>
        <Flex
          gap={{ base: '0.75rem', md: '0.5rem' }}
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding={{ base: '0.75rem 0.5rem', md: '1.5rem' }}
          borderRadius='0.5rem'
          backgroundColor='#fff'
          border='1px solid rgba(0, 0, 0, 0.1)'
        >
          <Flex justifyContent='center'>
            <CircleHeightSVG />
          </Flex>
          <Box>
            <Text fontFamily='heading' fontSize={{ base: '28px', md: '36px' }} color='black' lineHeight='100%'>
              {isMetric ? `${summary.height}cm` : `${summary.heightImperial.feets}'${summary.heightImperial.inches}''`}
            </Text>
            <Text fontSize={{ base: '12px', md: '14px' }}>Height</Text>
          </Box>
        </Flex>
        <Flex
          gap={{ base: '0.75rem', md: '0.5rem' }}
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding={{ base: '0.75rem 0.5rem', md: '1.5rem' }}
          borderRadius='0.5rem'
          backgroundColor='#fff'
          border='1px solid rgba(0, 0, 0, 0.1)'
        >
          <Flex justifyContent='center'>
            <CircleCurrentWeightSVG />
          </Flex>
          <Box flex={1}>
            <Text fontFamily='heading' fontSize={{ base: '28px', md: '36px' }} color='black' lineHeight='100%'>
              {summary.weight}
              {summary.units.weight}
            </Text>
            <Text fontSize={{ base: '12px', md: '14px' }}>Current weight</Text>
          </Box>
        </Flex>
        <Flex
          gap={{ base: '0.75rem', md: '0.5rem' }}
          flexDir={{ base: 'row', md: 'column' }}
          alignItems='center'
          padding={{ base: '0.75rem 0.5rem', md: '1.5rem' }}
          borderRadius='0.5rem'
          backgroundColor='#fff'
          border='1px solid rgba(0, 0, 0, 0.1)'
        >
          <Flex justifyContent='center'>
            <CircleTargetWeightSVG />
          </Flex>
          <Box flex={1}>
            <Text fontFamily='heading' fontSize={{ base: '28px', md: '36px' }} color='black' lineHeight='100%'>
              {summary.targetWeight}
              {summary.units.weight}
            </Text>
            <Text fontSize={{ base: '12px', md: '14px' }}>Target weight</Text>
          </Box>
        </Flex>
      </Grid>
      <Grid
        gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={{ base: '0.5rem', md: '0.75rem' }}
        marginBottom={{ base: '1rem', md: '2.5rem' }}
      >
        <BMIChart />
        <Flex
          flexDir='column'
          gap='1.25rem'
          borderRadius='0.5rem'
          padding={{ base: '1rem 0.75rem', md: '1.5rem' }}
          bg='#fff'
          border='1px solid rgba(0, 0, 0, 0.1)'
        >
          <Flex alignItems='center' gap='1rem'>
            <Flex>
              <CircleLifestyleSVG />
            </Flex>
            <Box>
              <Text fontSize='14px'>Lifestyle</Text>
              <Text fontSize='28px' fontFamily='heading' lineHeight='100%' color='black'>
                {/never/.test(lifestyle) ? `Very slow` : /Daily/.test(lifestyle) ? `Normal` : 'Slow'}
              </Text>
            </Box>
          </Flex>
          <Flex alignItems='center' gap='1rem'>
            <Flex>
              <CircleExerciseSVG />
            </Flex>
            <Box>
              <Text fontSize='14px'>Exercise level</Text>
              <Text fontSize='28px' fontFamily='heading' lineHeight='100%' color='black'>
                {/60\+/.test(workoutTimeDedication) ? 'High' : /45-60/.test(workoutTimeDedication) ? 'Normal' : 'Low'}
              </Text>
            </Box>
          </Flex>
          <Flex alignItems='center' gap='1rem'>
            <Flex>
              <CircleActivitySVG />
            </Flex>
            <Box>
              <Text fontSize='14px'>Activity level</Text>
              <Text fontSize='28px' fontFamily='heading' lineHeight='100%' color='black'>
                {/60\+/.test(workoutTimeDedication) ? 'High' : /45-60/.test(workoutTimeDedication) ? 'Normal' : 'Low'}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
};
