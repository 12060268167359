import { useMutation, UseMutationOptions } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';
import { LeadData, LeadResponse } from '~/types';

import { useAppLead } from './useAppLead';
import { useTracking } from './useTracking';

export const useLeadMutation = (
  options?: Omit<UseMutationOptions<LeadResponse, unknown, LeadData, unknown>, 'mutationKey'>,
) => {
  const { emailLeft } = useTracking();
  const { updateLead } = useAppLead();

  return useMutation<LeadResponse, unknown, LeadData>(FunctionName.CREATE_LEAD, {
    mutationFn: (data) => APIClient.post<LeadData, LeadResponse>(getAPIUrl(FunctionName.CREATE_LEAD), data),
    ...options,
    onMutate: (variables) => {
      if (options?.onMutate) {
        options.onMutate(variables);
      }

      return variables;
    },
    onSuccess: (data, variables, context) => {
      if (!variables?.id) {
        emailLeft({
          leadId: data.id,
        });
      }

      updateLead({ id: data.id });

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
