import { useMutation, UseMutationOptions } from 'react-query';

import { APIClient, FunctionName, getAPIUrl } from '~/firebase-init';
import { PaymentData } from '~/types';

interface PaymentResponse {
  id: string;
}

export const usePaymentMutation = (
  options?: Omit<UseMutationOptions<PaymentResponse, unknown, PaymentData, unknown>, 'mutationKey'>,
) => {
  return useMutation<PaymentResponse, unknown, PaymentData>(FunctionName.CREATE_PAYMENT, {
    mutationFn: (data) => APIClient.post<PaymentData, PaymentResponse>(getAPIUrl(FunctionName.CREATE_PAYMENT), data),
    ...options,
  });
};
