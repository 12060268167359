import addMonths from 'date-fns/addMonths';
import formatDate from 'date-fns/format';

interface SummarizeParams {
  age?: number;
  height?: number;
  weight: number;
  targetWeight: number;
}

interface SummarizeOptions {
  isMetric?: boolean;
  pointNotation?: number;
}

const DEFAULT_OPTIONS: SummarizeOptions = {
  isMetric: false,
  pointNotation: 0,
};

const summarize = (params: SummarizeParams, options = DEFAULT_OPTIONS) => {
  const { height = 0, weight, targetWeight, age = 0 } = normalizeParams(params);
  const opts = { ...DEFAULT_OPTIONS, ...options };
  const isMetricsUnitSystem = opts.isMetric;
  const pointNotation = opts.pointNotation;

  const heightNormalized = isMetricsUnitSystem ? inchesToCm(height) : height;
  const weightNormalized = isMetricsUnitSystem ? lbToKg(weight) : weight;
  const targetWeightNormalized = isMetricsUnitSystem ? lbToKg(targetWeight) : targetWeight;

  return {
    age,
    height: round(heightNormalized, pointNotation),
    heightImperial: {
      feets: inchesToFeets(height),
      inches: round(height - feetsToInches(inchesToFeets(height)), isMetricsUnitSystem ? 1 : 0),
    },
    weight: round(weightNormalized, pointNotation),
    targetWeight: round(targetWeightNormalized, pointNotation),
    weightDifference: round(weightNormalized - targetWeightNormalized, pointNotation),
    units: {
      weight: isMetricsUnitSystem ? 'kg' : 'lb',
    },
    bmi: getBMISummary(weight, height),
    bmr: getBMRSummary(weight, height, age),
    forecast: getForecastDetails(weight, targetWeight, { isMetric: isMetricsUnitSystem }),
  };
};

const getBMISummary = (weight: number, height: number) => {
  const score = round((weight / (height * height)) * 703, 1);

  const getCategoryAndKey = () => {
    if (score <= 18.5) {
      return {
        range: '< 18.5',
        category: 'Underweight',
        key: 'underweight',
      };
    } else if (score >= 18.5 && score <= 24.9) {
      return {
        range: '18.5 - 24.9',
        category: 'Normal',
        key: 'normal',
      };
    } else if (score > 25 && score <= 29.9) {
      return {
        range: '25 - 30',
        category: 'overweight',
        key: 'overweight',
      };
    }

    return {
      range: '> 30',
      category: 'Obese',
      key: 'obese',
    };
  };

  return {
    score,
    ...getCategoryAndKey(),
  };
};

const getBMRSummary = (weight: number, height: number, age: number) => {
  const perDay = Math.abs(Math.round(10 * lbToKg(weight) + 6.25 * inchesToCm(height) - 5 * age - 161));

  return {
    perDay,
  };
};

const getForecastDetails = (weight: number, targetWeight: number, options?: { isMetric?: boolean }) => {
  const diff = weight - targetWeight;
  const afterFirstMonth = calcFirstMonthWeightLoss(weight, targetWeight);

  const durationInMonths = Math.round(diff / afterFirstMonth) || 1;

  return {
    afterFirstMonth: options?.isMetric ? lbToKg(afterFirstMonth) : afterFirstMonth,
    durationInMonths,
    getGoalDate: (format: 'MMM d, yyyy' | 'MMMM') => {
      return formatDate(addMonths(new Date(), durationInMonths), format);
    },
  };
};

const normalizeParams = (params: SummarizeParams) => {
  const newParams: SummarizeParams = { ...params };

  Object.keys(params).map((paramKey) => {
    const paramValue = params[paramKey as keyof SummarizeParams];
    if (typeof paramValue === 'number' && isNaN(paramValue)) {
      newParams[paramKey as keyof SummarizeParams] = 0;
    }

    return null;
  });

  return newParams;
};

const inchesToFeets = (inches: number) => Math.floor(inches / 12);
const feetsToInches = (feets: number) => Math.floor(feets * 12);
const round = (number: number, round = DEFAULT_OPTIONS.pointNotation) => parseFloat(number.toFixed(round));
const lbToKg = (lb: number) => lb / 2.20462262;
const inchesToCm = (inches: number) => inches * 2.54;

const calcFirstMonthWeightLoss = (weight: number, targetWeight: number): number => {
  const diff = weight - targetWeight;

  if (diff - 10 <= 0) {
    return diff;
  } else if (diff < 11) {
    return 10;
  } else if (diff < 22) {
    return 14;
  } else if (diff < 33) {
    return 18;
  } else if (diff < 44) {
    return 22;
  } else {
    return 26;
  }
};

export default summarize;
