import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export const ModalButtonWithQuestions = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay onClick={onClose} />
        <ModalContent maxWidth='40rem'>
          <ModalCloseButton color='black' border='none' cursor='pointer' />
          <ModalBody paddingTop='3rem'>
            <Text
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            >
              What is the Lazy Cardio Challenge?
            </Text>
            <Text marginBottom='2rem' color='rgba(0, 0, 0, 0.8)'>
              The Lazy Cardio Challenge is your golden ticket to making your weight loss journey easier. Plus, it gives
              you a chance to get your money back when you shed 7 lbs along the way!
            </Text>
            <Text
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            >
              How does it work?
            </Text>
            <Text marginBottom='2rem' color='rgba(0, 0, 0, 0.8)'>
              It&apos;s a breeze! Just pick a Lazy Cardio plan – 6, 3, or 1 months – and you&apos;re in. All you need to
              do is track your progress while working towards shedding those 7 lbs (don&apos;t lie to yourself,
              please!). We&apos;ll keep you updated via email after you achieve your goal.
            </Text>
            <Text
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            >
              What if I don&apos;t want to participate?
            </Text>
            <Text marginBottom='2rem' color='rgba(0, 0, 0, 0.8)'>
              No worries! The Lazy Cardio Challenge is completely optional and totally free. You can still enjoy all the
              Lazy Cardio app features even if you decide not to take part.
            </Text>
            <Text
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            >
              How do I start?
            </Text>
            <Text marginBottom='2rem' color='rgba(0, 0, 0, 0.8)'>
              To kick things off, grab your Lazy Cardio plan. You&apos;ll receive your app login details and you are
              ready to go.
            </Text>
            <Text
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            >
              What happens at the end of the challenge?
            </Text>
            <Text marginBottom='2rem' color='rgba(0, 0, 0, 0.8)'>
              Once you&apos;ve successfully lost 7 lbs within the initial plan period, you&apos;ll receive a big
              congrats from us and a full refund of your plan&apos;s cost. Your Lazy Cardio plan becomes free!
            </Text>
            <Text
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight={700}
              marginBottom={{ base: '0.5rem', md: '0.75rem' }}
              color='black'
            >
              Is there any fine print you should know about?
            </Text>
            <Text marginBottom='2rem' color='rgba(0, 0, 0, 0.8)'>
              Absolutely not. There are no hidden tricks here. The Lazy Cardio Challenge is our way of showing our total
              dedication to your success and our strong belief in the power of our plans. So, are you ready to make this
              incredible journey?
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button onClick={onOpen} width='100%' maxWidth={{ base: 'none', md: '20rem' }}>
        Click to learn more
      </Button>
    </>
  );
};
