import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { FormEvent, memo, useRef } from 'react';

import SafeWebSVG from '~/assets/icons/safe_web.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCookies } from '~/hooks/useCookies';
import { useLeadMutation } from '~/hooks/useLeadMutation';
import { AppRoute } from '~/types';

import { Arrow } from '../Arrow';

export const EmailForm = memo(() => {
  const cookies = useCookies();
  const { pushNextRoute } = useAppRouter(AppRoute.SUMMARY);
  const { mutate, isLoading } = useLeadMutation({
    onMutate: () => {
      pushNextRoute();
    },
  });
  const { quizAnswers, id } = useAppLead();
  const emailRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = emailRef?.current?.value;

    mutate({
      id,
      email,
      quizAnswers,
      customFields: cookies,
    });
  };

  return (
    <>
      <Box as='form' width='100%' onSubmit={handleSubmit} maxW='32rem'>
        <Input required ref={emailRef} type='email' marginBottom='1rem' placeholder='Your email address' />
        <Flex gap='0.75rem' alignItems='center' marginBottom='1.5rem'>
          <Flex>
            <SafeWebSVG />
          </Flex>
          <Text fontSize='13px' color='black50'>
            We don&apos;t send spam or share email addresses. We respect your privacy.
          </Text>
        </Flex>
        <Button type='submit' isLoading={isLoading}>
          Continue <Arrow dir='right' fill='white' />
        </Button>
      </Box>
    </>
  );
});

EmailForm.displayName = 'EmailForm';
