import { useMemo } from 'react';

import { useProductsQuery } from './useProductsQuery';

export const useProducts = () => {
  const { data } = useProductsQuery();
  const products = useMemo(() => data?.products || [], [data]);

  const findProductById = (productId: string) => {
    return products.find(({ id }) => id === productId);
  };

  const mainProducts = {
    1: findProductById('3'),
    3: findProductById('2'),
    6: findProductById('1'),
  };

  return {
    products,
    findProductById,
    mainProducts,
  };
};
