import { Box, Flex, Text } from '@chakra-ui/react';
import { OrderInterface, PaymentElement, StripeWrapper } from '@shared/payments';

import CircleCancelSVG from '~/assets/icons/circle_cancel.svg';
import CircleCardSVG from '~/assets/icons/circle_card.svg';
import CircleSecureSVG from '~/assets/icons/circle_secure.svg';
import { config } from '~/config';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCheckout } from '~/hooks/useCheckout';
import { OrderSummary } from '~/layouts/OrderSummary';
import { AppRoute } from '~/types';

import { Arrow } from '../Arrow';

export const CheckoutForm = () => {
  const { id, selectedPlan } = useAppLead();
  const { createOrder, trackPurchase, finalPrice } = useCheckout();
  const { pushNextRoute, back } = useAppRouter(AppRoute.SUCCESS);

  const handlePaymentSuccess = (order: OrderInterface) => {
    trackPurchase(String(selectedPlan?.id), { method: order?.method });

    pushNextRoute();
  };

  return (
    <Box width='100%'>
      <Flex
        alignItems='center'
        gap='0.5rem'
        marginBottom={{ base: '0.75rem', md: '1rem' }}
        onClick={back}
        cursor='pointer'
      >
        <Arrow dir='left' />
        <Text color='rgba(0, 0, 0, 0.8)' fontSize='14px'>
          Back to plans
        </Text>
      </Flex>
      <Flex
        alignItems='flex-start'
        flexDir={{ base: 'column-reverse', md: 'row' }}
        gap={{ base: '0.75rem', md: '1rem' }}
      >
        <Box flex={1} width='100%'>
          <Box
            bg='white'
            padding={{ base: '1rem', md: '1.5rem' }}
            borderRadius='0.5rem'
            border='1px solid rgba(0, 0, 0, 0.1)'
            marginBottom='1rem'
          >
            <StripeWrapper apiKey={config.STRIPE.API_KEY}>
              <PaymentElement.Paypal
                createOrder={createOrder}
                forcePPRerender={[id]}
                onSuccess={handlePaymentSuccess}
                options={{ isSubscription: false }}
              />
              <Box marginTop='0.5rem'>
                <PaymentElement.PaymentButtons
                  createOrder={createOrder}
                  onSuccess={handlePaymentSuccess}
                  options={{
                    currency: 'USD',
                    amount: finalPrice,
                    title: selectedPlan ? `${selectedPlan.title}` : undefined,
                  }}
                />
              </Box>
              <Flex alignItems='center' gap='1rem' marginY='0.75rem'>
                <Box flex={1} height='1px' bg='rgba(0, 0, 0, 0.1)' />
                <Text fontSize='12px' fontWeight={400} textAlign='center'>
                  OR CREDIT CARD
                </Text>
                <Box flex={1} height='1px' bg='rgba(0, 0, 0, 0.1)' />
              </Flex>
              <PaymentElement.StripeCustomForm createOrder={createOrder} onSuccess={handlePaymentSuccess} />
            </StripeWrapper>
          </Box>
          <Flex gap='1rem' flexWrap='wrap' justifyContent='center' alignItems='center'>
            <Flex gap='0.5rem' alignItems='center'>
              <CircleCardSVG />
              <Text fontWeight={700} fontSize='14px' color='black'>
                No hidden fees
              </Text>
            </Flex>
            <Flex gap='0.5rem' alignItems='center'>
              <CircleSecureSVG />
              <Text fontWeight={700} fontSize='14px' color='black'>
                Secure SSL-protected
              </Text>
            </Flex>
            <Flex gap='0.5rem' alignItems='center'>
              <CircleCancelSVG />
              <Text fontWeight={700} fontSize='14px' color='black'>
                Cancel Anytime
              </Text>
            </Flex>
          </Flex>
        </Box>
        <OrderSummary
          flex={1}
          bg='white'
          padding={{ base: '1.25rem', md: '1.5rem' }}
          borderRadius='0.5rem'
          border='1px solid rgba(0, 0, 0, 0.1)'
          width='100%'
        />
      </Flex>
    </Box>
  );
};
