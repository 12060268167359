import { Box, Flex, Text } from '@chakra-ui/react';
import format from 'date-fns/format';
import { motion } from 'framer-motion';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

export const SummaryWeightChart = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { summary } = useAppLead();

  return (
    <Box {...props} ref={ref}>
      <Text marginBottom='0.5rem' fontSize='14px'>
        According to the information you have provided us, you&apos;ll achieve your goal weight of
      </Text>
      <Text fontWeight={700} marginBottom='1rem' fontSize='18px' color='black'>
        {`${summary.targetWeight}${summary.units.weight} by ${summary.forecast.getGoalDate('MMM d, yyyy')}`}
      </Text>
      <Flex flexDir='column'>
        <Flex position='relative' marginBottom='0.25rem'>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2, duration: 0.1 }}>
            <Text
              position='absolute'
              top={{ base: '62.5%', md: '63%' }}
              right={{ base: '3%', md: '4%' }}
              fontWeight={700}
              color='black'
              width='60px'
              textAlign='center'
            >{`${summary.targetWeight}${summary.units.weight}`}</Text>
          </motion.div>
          <Text
            position='absolute'
            top='3.7%'
            left={{ base: '2.7%', md: '3.5%' }}
            fontWeight={700}
            width='60px'
            textAlign='center'
            color='white'
          >{`${summary.weight}${summary.units.weight}`}</Text>
          <ProgressChartSVG />
        </Flex>
        <Flex justifyContent='space-between' marginX='0.5rem'>
          <Text fontSize='14px' width='21%' textAlign='center'>
            {format(new Date(), 'MMMM')}
          </Text>
          <Text fontSize='14px' width='21%' textAlign='center'>
            {summary.forecast.getGoalDate('MMMM')}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
});

SummaryWeightChart.displayName = 'SummaryWeightChart';

const ProgressChartSVG = () => (
  <motion.svg width='100%' height='auto' viewBox='0 0 313 244' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1253_932)'>
      <mask id='mask0_1253_932' maskUnits='userSpaceOnUse' x='0' y='0' width='313' height='244'>
        <path d='M0.211602 0H312.785V243.75H0.211602V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_1253_932)'>
        <motion.path
          d='M37.8516 58.6191V204.335V240.909'
          stroke='#EF7E32'
          strokeWidth='3.73219'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='4.66 9.33'
        />
        <motion.path
          d='M274.789 204.26C274.789 204.26 274.789 234 274.789 237.5'
          stroke='#0066FF'
          strokeWidth='2.86765'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='4.78 9.56'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.2,
            ease: 'easeInOut',
            delay: 1.8,
          }}
          opacity={0}
        />
        <g opacity='0.1'>
          <path
            d='M6.72079 237.011V237.52C6.72079 237.661 6.84121 237.775 6.99033 237.775H305.524C305.672 237.775 305.793 237.661 305.793 237.52V237.011C305.793 236.87 305.672 236.757 305.524 236.757H6.99033C6.84121 236.757 6.72079 236.87 6.72079 237.011ZM6.72079 58.8015V59.3106C6.72079 59.4511 6.84121 59.5653 6.99033 59.5653H305.524C305.672 59.5653 305.793 59.4511 305.793 59.3106V58.8015C305.793 58.661 305.672 58.5469 305.524 58.5469H6.99033C6.84121 58.5469 6.72079 58.661 6.72079 58.8015ZM6.72079 94.4434V94.9525C6.72079 95.093 6.84121 95.2072 6.99033 95.2072H305.524C305.672 95.2072 305.793 95.093 305.793 94.9525V94.4434C305.793 94.3029 305.672 94.1888 305.524 94.1888H6.99033C6.84121 94.1888 6.72079 94.3029 6.72079 94.4434ZM6.72079 130.085V130.595C6.72079 130.735 6.84121 130.849 6.99033 130.849H305.524C305.672 130.849 305.793 130.735 305.793 130.595V130.085C305.793 129.944 305.672 129.831 305.524 129.831H6.99033C6.84121 129.831 6.72079 129.944 6.72079 130.085ZM6.72079 165.727V166.236C6.72079 166.377 6.84121 166.491 6.99033 166.491H305.524C305.672 166.491 305.793 166.377 305.793 166.236V165.727C305.793 165.586 305.672 165.473 305.524 165.473H6.99033C6.84121 165.473 6.72079 165.586 6.72079 165.727ZM6.72079 201.369V201.878C6.72079 202.019 6.84121 202.133 6.99033 202.133H305.524C305.672 202.133 305.793 202.019 305.793 201.878V201.369C305.793 201.228 305.672 201.115 305.524 201.115H6.99033C6.84121 201.115 6.72079 201.228 6.72079 201.369Z'
            fill='black'
          />
        </g>
        <motion.path
          initial={{ pathLength: 0, pathOffset: 1 }}
          animate={{ pathLength: 1, pathOffset: 0 }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            delay: 0.2,
          }}
          d='M274.07 200.856C213.569 197.394 240 157.5 180.632 145.183C121 139.5 139.5 111 98.468 102.67C56 94.0492 44.8526 90.9182 37.9664 59.1445'
          stroke='#0066FF'
          strokeWidth='5.98735'
          pathLength={0}
        />
        <motion.path
          d='M37.9683 47.5078C32.7979 47.5078 28.6007 51.7054 28.6007 56.8755C28.6007 62.0454 32.7979 66.2431 37.9683 66.2431C43.1377 66.2431 47.3359 62.0454 47.3359 56.8755C47.3359 51.7054 43.1377 47.5078 37.9683 47.5078Z'
          fill='#EF7E32'
        />
        <motion.path
          d='M37.9683 47.5078C32.7979 47.5078 28.6007 51.7054 28.6007 56.8755C28.6007 62.0454 32.7979 66.2431 37.9683 66.2431C43.1377 66.2431 47.3359 62.0454 47.3359 56.8755C47.3359 51.7054 43.1377 47.5078 37.9683 47.5078Z'
          stroke='#FFF8F4'
          strokeWidth='5.35294'
        />
        <g mask='url(#mask2_1253_932)'>
          <motion.path
            d='M49.1988 36.0133H58.509L59.1877 36.0037C62.0936 35.9655 63.2407 35.6118 64.4069 34.9905C65.6495 34.3214 66.6245 33.3464 67.2936 32.1037L67.4274 31.8457C67.9914 30.7177 68.2973 29.4751 68.3164 26.5596V11.4089C68.3164 7.99641 67.9627 6.76333 67.2936 5.51112C66.6245 4.26847 65.6495 3.29347 64.4069 2.62436L64.1487 2.49053C63.0208 1.92656 61.7782 1.62068 58.8627 1.60156H16.9473C13.5348 1.60156 12.3017 1.95524 11.0495 2.62436C9.80684 3.29347 8.83185 4.26847 8.16274 5.51112L8.0289 5.76921C7.44581 6.94494 7.13993 8.23538 7.13993 11.4089V26.2059L7.14947 26.8846C7.18771 29.7905 7.54141 30.9376 8.16274 32.1037C8.83185 33.3464 9.80684 34.3214 11.0495 34.9905L11.3076 35.1243C12.4833 35.7074 13.7737 36.0133 16.9473 36.0133H26.2576C33.312 36.0133 33.312 43.6604 37.7282 43.6604C42.1443 43.6604 42.1443 36.0133 49.1988 36.0133Z'
            fill='#FF8833'
          />
        </g>
        <motion.path
          d='M274.55 191.846C269.38 191.846 265.183 196.043 265.183 201.213C265.183 206.384 269.38 210.581 274.55 210.581C279.72 210.581 283.918 206.384 283.918 201.213C283.918 196.043 279.72 191.846 274.55 191.846Z'
          fill='#0066FF'
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            duration: 0.2,
            ease: 'easeInOut',
            delay: 1.8,
          }}
          scale={0}
          opacity={0}
        />
        <motion.path
          d='M274.55 191.846C269.38 191.846 265.183 196.043 265.183 201.213C265.183 206.384 269.38 210.581 274.55 210.581C279.72 210.581 283.918 206.384 283.918 201.213C283.918 196.043 279.72 191.846 274.55 191.846Z'
          stroke='#FFF8F4'
          strokeWidth='5.35294'
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            duration: 0.2,
            ease: 'easeInOut',
            delay: 1.8,
          }}
          scale={0}
          opacity={0}
        />
        <mask id='mask1_1253_932' maskUnits='userSpaceOnUse' x='242' y='144' width='64' height='45'>
          <path d='M305.883 144.453H242.259V188.194H305.883V144.453Z' fill='white' />
        </mask>
        <g mask='url(#mask1_1253_932)'>
          <path
            d='M305.016 148.796C305.883 150.496 305.883 152.724 305.883 157.178V167.517C305.883 171.97 305.883 174.198 305.016 175.899C304.253 177.395 303.037 178.612 301.541 179.374C299.84 180.241 297.612 180.241 293.158 180.241H286C278.663 180.241 278.663 188.194 274.071 188.194C269.479 188.194 269.479 180.241 262.142 180.241H254.984C250.53 180.241 248.302 180.241 246.602 179.374C245.105 178.612 243.889 177.395 243.126 175.899C242.259 174.198 242.259 171.97 242.259 167.517V157.178C242.259 152.724 242.259 150.496 243.126 148.796C243.889 147.3 245.105 146.083 246.602 145.32C248.302 144.453 250.53 144.453 254.984 144.453H293.158C297.612 144.453 299.84 144.453 301.541 145.32C303.037 146.083 304.253 147.3 305.016 148.796Z'
            fill='white'
          />
          <motion.path
            d='M305.387 157.177C305.387 154.941 305.387 153.295 305.28 151.993C305.174 150.698 304.966 149.789 304.575 149.02C303.86 147.617 302.719 146.476 301.316 145.762C300.547 145.37 299.638 145.162 298.342 145.055C297.041 144.949 295.395 144.949 293.159 144.949H254.985C252.749 144.949 251.103 144.949 249.802 145.055C248.506 145.162 247.597 145.37 246.828 145.762C245.426 146.476 244.284 147.617 243.571 149.02C243.178 149.789 242.97 150.698 242.864 151.993C242.757 153.295 242.757 154.941 242.757 157.177V167.516C242.757 169.751 242.757 171.398 242.864 172.699C242.97 173.995 243.178 174.903 243.571 175.672C244.284 177.075 245.426 178.216 246.828 178.93C247.597 179.323 248.506 179.53 249.802 179.637C251.103 179.743 252.749 179.743 254.985 179.743H262.143C264.076 179.743 265.548 180.27 266.734 181.071C267.909 181.864 268.781 182.915 269.535 183.919C269.719 184.165 269.896 184.407 270.067 184.64C270.609 185.384 271.1 186.056 271.65 186.596C272.35 187.281 273.094 187.696 274.072 187.696C275.05 187.696 275.794 187.281 276.494 186.596C277.044 186.056 277.535 185.384 278.077 184.64C278.248 184.407 278.424 184.165 278.609 183.919C279.363 182.915 280.235 181.864 281.41 181.071C282.596 180.27 284.068 179.743 286.001 179.743H293.159C295.395 179.743 297.041 179.743 298.342 179.637C299.638 179.53 300.547 179.323 301.316 178.93C302.719 178.216 303.86 177.075 304.575 175.672C304.966 174.903 305.174 173.995 305.28 172.699C305.387 171.398 305.387 169.751 305.387 167.516V157.177Z'
            stroke='#E6E6E6'
            strokeWidth='0.994118'
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              duration: 0.2,
              ease: 'easeInOut',
              delay: 1.8,
            }}
            scale={0}
            opacity={0}
          />
        </g>
        <mask id='mask2_1253_932' maskUnits='userSpaceOnUse' x='7' y='1' width='62' height='43'>
          <path d='M68.5547 1.43359H7.37822V43.4924H68.5547V1.43359Z' fill='white' />
        </mask>
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1253_932'>
        <rect width='313' height='244' fill='white' />
      </clipPath>
    </defs>
  </motion.svg>
);
