import { Box, Flex, Text } from '@chakra-ui/react';

import BMIProgressSVG from '~/assets/icons/bmi_progress.svg';

import { useAppLead } from '../hooks/useAppLead';

export const BMIChart = () => {
  const { summary } = useAppLead();
  const { category } = summary.bmi;

  return (
    <Box
      width='100%'
      borderRadius='0.5rem'
      padding={{ base: '1rem 0.75rem', md: '1.5rem' }}
      backgroundColor='#fff'
      border='1px solid rgba(0, 0, 0, 0.1)'
    >
      <Text fontSize='14px' marginBottom='0.25rem'>
        Body mass index (BMI)
      </Text>
      <Text
        color={
          category === 'Underweight'
            ? '#58CEC8'
            : category === 'Normal'
              ? '#44AB38'
              : category === 'Overweight'
                ? '#FD6A78'
                : '#FD6A78'
        }
        fontFamily='heading'
        lineHeight='100%'
        marginBottom='0.75rem'
        fontSize='28px'
      >
        {category === 'Underweight'
          ? 'Underweight: < 18.5'
          : category === 'Normal'
            ? 'Normal: 18.9-24.9'
            : category === 'Overweight'
              ? 'Overweight: 25-29.9'
              : 'Obese: 30-35<'}
      </Text>
      <Flex
        marginLeft={
          category === 'Underweight' ? '4%' : category === 'Normal' ? '33%' : category === 'Overweight' ? '63%' : '88%'
        }
        display='inline-flex'
        flexDirection='column'
        marginBottom='-0.125rem'
      >
        <Flex
          display='block'
          padding='0.5rem'
          lineHeight='100%'
          backgroundColor='system.white'
          borderRadius='0.25rem'
          boxShadow='0px 4px 16px 0px rgba(0, 0, 0, 0.16)'
          fontSize='12px'
          fontWeight={700}
        >
          YOU
        </Flex>
        <Flex position='relative' top='-1px' zIndex={1} justifyContent='center'>
          <svg xmlns='http://www.w3.org/2000/svg' width='20' height='7' viewBox='0 0 20 7' fill='none'>
            <path
              d='M12.4988 6.00097C11.0379 7.16967 8.96212 7.16968 7.50124 6.00098L0 -1.74846e-06L20 0L12.4988 6.00097Z'
              fill='white'
            />
          </svg>
        </Flex>
      </Flex>
      <BMIProgressSVG />
      <Flex justifyContent='space-between' textAlign='center'>
        <Text
          fontSize={{ base: '11px', md: '12px' }}
          fontWeight={category === 'Underweight' ? 700 : 400}
          color={category === 'Underweight' ? '#FD6A78' : '#9DA2B0'}
        >
          Underweight:
          <br />
          {`< 18.5`}
        </Text>
        <Text
          fontSize={{ base: '11px', md: '12px' }}
          fontWeight={category === 'Normal' ? 700 : 400}
          color={category === 'Normal' ? '#44AB38' : '#9DA2B0'}
        >
          Normal:
          <br />
          18.5-24.9
        </Text>
        <Text
          fontSize={{ base: '11px', md: '12px' }}
          fontWeight={category === 'Overweight' ? 700 : 400}
          color={category === 'Overweight' ? '#FD6A78' : '#9DA2B0'}
        >
          Overweight:
          <br />
          25-29.9
        </Text>
        <Text
          fontSize={{ base: '11px', md: '12px' }}
          fontWeight={category === 'Obese' ? 700 : 400}
          color={category === 'Obese' ? '#FD6A78' : '#9DA2B0'}
        >
          Obese:
          <br />
          {`30-35<`}
        </Text>
      </Flex>
      {/* <Flex gap='0.5rem'>
          <Flex>
            <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_180_2866)'>
                <path
                  d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z'
                  fill='#233E20'
                />
              </g>
              <defs>
                <clipPath id='clip0_180_2866'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </Flex>
          <Text fontSize='14px' lineHeight='130%'>
            You would reach your <b>perfect BMI</b> level after <b>{`${weightLossTimeInMonths} months`}</b> using our
            program
          </Text>
        </Flex> */}
      {/* {category !== 'Normal' && (
        <InfoBlock
          title='☝️ Risks for an unhealthy BMI'
          description='High blood pressure, heart disease, stroke, type 2 diabetes, some types of cancer, osteoarthritis, back pain, all case mortality.'
          backgroundColor='#FFF2C8'
        />
      )} */}
    </Box>
  );
};
