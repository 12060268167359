import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';

import ArrowSVG from '~/assets/icons/arrow.svg';
import { colors } from '~/theme';

type ArrowDir = 'left' | 'right' | 'top' | 'bottom';

export const Arrow: FC<FlexProps & { dir?: ArrowDir; fill?: string }> = ({
  dir = 'left',
  fill = colors.black,
  ...props
}) => {
  const dirToDeg: Record<'left' | 'right' | 'top' | 'bottom', number> = {
    bottom: -90,
    left: 0,
    right: 180,
    top: 90,
  };

  return (
    <Flex {...props} transform={`rotate(${dirToDeg[dir]}deg)`}>
      <ArrowSVG stroke={fill} />
    </Flex>
  );
};
